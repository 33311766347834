import React, { useState, createContext, useCallback } from 'react';

export const LodgeContext = createContext({
  lodgeData: {},
  setLodgeData: () => {},
});

export const LodgeContextProvider = ({ children }) => {
  const [lodgeData, setData] = useState({
    lodgeColor: null,
  });

  const setLodgeData = useCallback(
    (lodgeColor) => {
      setData({ lodgeColor });
    },
    [setData],
  );

  return (
    <LodgeContext.Provider value={{ lodgeData, setLodgeData }}>
      {children}
    </LodgeContext.Provider>
  );
};
