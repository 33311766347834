import React from 'react';
import { Flex, Box } from '../Rebass';
import theme from '../../theme';
const MenuPrimary = ({ bg, children }) => {
    return (React.createElement(Box, { sx: {
            height: ['auto', 'auto', 'auto', '100%'],
            transition: `background-color ${theme.speed.default} ease`,
        }, bg: bg, width: 1, pt: [14, 14], px: 4, pb: [6, 6, 6, 10], "data-testid": "menuPrimary" },
        React.createElement(Flex, { flexDirection: "column", justifyContent: "space-between", px: [0, 14], sx: {
                maxWidth: 1400,
                mx: 'auto',
                height: ['auto', '100%'],
                position: 'relative',
            } }, children)));
};
export default MenuPrimary;
