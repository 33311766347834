import { theme } from '@singita/components';
import React, { useState, createContext } from 'react';

export const NavbarContext = createContext({
  styles: {},
  setNavbarStyles: () => {},
  height: 0,
  setNavbarHeight: () => {},
});

export const NavbarContextProvider = ({ children }) => {
  const [navbarHeight, setNavbarHeight] = useState(0);
  const [navbarStyles, setNavbarStyles] = useState({
    logoColor: theme.colors.baseWhite,
  });

  return (
    <NavbarContext.Provider
      value={{ navbarStyles, navbarHeight, setNavbarHeight, setNavbarStyles }}
    >
      {children}
    </NavbarContext.Provider>
  );
};
