import React from 'react';
import { Box, Flex } from '../Rebass';
import Meta from '../Meta';
import Heading from '../Heading';
const AuthorExtended = ({ name, role, image, description = null, region, }) => {
    return (React.createElement(Flex, { flexDirection: ['column', 'row', 'row', 'row'], alignItems: "flex-start", mb: [3] },
        image && (React.createElement(Box, { mr: [0, 4], mb: [4, 0], flex: [null, '1 0 auto'], width: ['100%', 'unset'] }, image)),
        React.createElement(Flex, { flexDirection: "column", maxWidth: "700px", width: [1, 7 / 12, 7 / 12, 9 / 12] },
            React.createElement(Box, { mb: [2] },
                React.createElement(Meta, { meta: [
                        { text: role, color: 'textDark' },
                        ...(region ? [{ text: region, color: 'brandBrown' }] : []),
                    ], size: "body" })),
            React.createElement(Box, { mb: [2] },
                React.createElement(Heading, { size: "h1", as: "h1" }, name)),
            React.createElement(Box, { mb: [3], width: [1] }, description && description))));
};
export default AuthorExtended;
