module.exports = [
  {
    default: true,
    path: '',
    label: 'English',
    locale: 'en-US',
    isoCode: 'en',
  },
  {
    path: 'de',
    label: 'German',
    locale: 'de',
    isoCode: 'de',
  }
  //,
  // {
  //   path: 'pt',
  //   label: 'Portuguese',
  //   locale: 'pt',
  //   isoCode: 'pt',
  // },
  // {
  //   path: 'es',
  //   label: 'Spanish',
  //   locale: 'es',
  //   isoCode: 'es',
  // },
];
