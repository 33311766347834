import { createMedia } from '@artsy/fresnel';
import { breakpoints } from '@singita/components';

const { sm, md, lg, xl } = breakpoints;

const AppMedia = createMedia({
  breakpoints: {
    xs: 0,
    sm,
    md,
    lg,
    xl,
  },
});

export const mediaStyles = AppMedia.createMediaStyle();
export const { Media, MediaContextProvider } = AppMedia;
