import { Global, css, ThemeProvider } from '@emotion/react';
import React from 'react';
import { ResourceSetProvider } from './src/context/ResourceSetContext';
import { NavbarContextProvider } from './src/context/NavbarContext';
import { AvailabilityProvider } from './src/context/AvailabilityContext';
import { theme } from '@singita/components';
import { MediaContextProvider } from './src/Media';
import { ContentModalProvider } from './src/context/ContentModalContext';
import { LodgeContextProvider } from './src/context/LodgeContext';
import { VideoProvider } from './src/context/VideoContext';

export const wrapRootElement = ({ element }) => (
  <MediaContextProvider>
    <ResourceSetProvider>
      <AvailabilityProvider>
        <LodgeContextProvider>
          <NavbarContextProvider>
            <ContentModalProvider>
              <VideoProvider>
                <ThemeProvider theme={theme}>
                  <Global
                    styles={css`
                      *,
                      *:before,
                      *:after {
                        box-sizing: border-box;
                      }
                      html {
                        box-sizing: border-box;
                        font-family: ${theme.fonts.sans};
                        font-size: 16px;
                      }
                      html,
                      body,
                      div,
                      span,
                      applet,
                      object,
                      iframe,
                      h1,
                      h2,
                      h3,
                      h4,
                      h5,
                      h6,
                      p,
                      blockquote,
                      pre,
                      a,
                      abbr,
                      acronym,
                      address,
                      big,
                      cite,
                      code,
                      del,
                      dfn,
                      em,
                      img,
                      ins,
                      kbd,
                      q,
                      s,
                      samp,
                      small,
                      strike,
                      strong,
                      sub,
                      sup,
                      tt,
                      var,
                      b,
                      u,
                      i,
                      center,
                      dl,
                      dt,
                      dd,
                      ol,
                      ul,
                      li,
                      fieldset,
                      form,
                      label,
                      legend,
                      table,
                      caption,
                      tbody,
                      tfoot,
                      thead,
                      tr,
                      th,
                      td,
                      article,
                      aside,
                      canvas,
                      details,
                      embed,
                      figure,
                      figcaption,
                      footer,
                      header,
                      hgroup,
                      menu,
                      nav,
                      output,
                      ruby,
                      section,
                      summary,
                      time,
                      mark,
                      audio,
                      video {
                        margin: 0;
                        padding: 0;
                        border: 0;
                        font-size: 100%;
                        font: inherit;
                        vertical-align: baseline;
                      }
                      article,
                      aside,
                      details,
                      figcaption,
                      figure,
                      footer,
                      header,
                      hgroup,
                      menu,
                      nav,
                      section {
                        display: block;
                      }
                      body {
                        line-height: 1;
                      }
                      ol,
                      ul {
                        list-style: none;
                      }
                      blockquote,
                      q {
                        quotes: none;
                      }
                      blockquote:before,
                      blockquote:after,
                      q:before,
                      q:after {
                        content: '';
                        content: none;
                      }
                      table {
                        border-collapse: collapse;
                        border-spacing: 0;
                      }
                      body {
                        line-height: 1.5;
                        letter-spacing: 0;
                        background-color: #f7fafc;
                      }

                      a {
                        text-decoration: none;
                        color: inherit;
                      }

                      body.no-scroll {
                        overflow-y: hidden;
                      }
                    `}
                  />

                  {element}
                </ThemeProvider>
              </VideoProvider>
            </ContentModalProvider>
          </NavbarContextProvider>
        </LodgeContextProvider>
      </AvailabilityProvider>
    </ResourceSetProvider>
  </MediaContextProvider>
);
