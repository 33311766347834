import React, { createContext, useState } from 'react';

export const VideoContext = createContext({
  isOpen: false,
  setIsOpen: () => {},
  videoUrl: null,
});

export const VideoProvider = ({ children }) => {
  const initialValue = { isOpen: false, videoUrl: null };
  const [state, setState] = useState(initialValue);

  return (
    <VideoContext.Provider
      value={{
        ...state,
        setIsOpen: (videoUrl, isOpen) => {
          if (isOpen) {
            setState({ isOpen, videoUrl });
          } else {
            setState(initialValue);
          }
        },
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};
