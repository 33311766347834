import React, { createContext, useState } from 'react';

const ContentModalContext = createContext({
  modalProps: {
    isOpen: false,
    item: null,
  },
  toggleModal: () => {},
  closeModal: () => {},
});

export const ContentModalProvider = ({ children }) => {
  const [modalProps, setModalProps] = useState({ isOpen: false, item: null });

  return (
    <ContentModalContext.Provider
      value={{
        modalProps: { isOpen: modalProps.isOpen, item: modalProps.item },
        closeModal: () => setModalProps({ isOpen: false, item: null }),
        toggleModal: (item) => {
          if (modalProps.isOpen) {
            setModalProps({ isOpen: false, item: null });
          } else {
            setModalProps({ isOpen: true, item });
          }
        },
      }}
    >
      {children}
    </ContentModalContext.Provider>
  );
};

export default ContentModalContext;
