import React from 'react';
import { Box } from '../Rebass';
import { theme } from '../..';
import Heading from '../Heading';
const Contact = ({ email, telephone, color = theme.colors.baseBlack, children, }) => {
    return (React.createElement(Box, null,
        React.createElement(Box, { mb: [2] },
            email && (React.createElement(Heading, { mb: [1], size: "h4", fontWeight: "normal", color: color }, email)),
            telephone && (React.createElement(Heading, { mb: [1], size: "h4", fontWeight: "normal", color: color }, telephone))),
        children));
};
export default Contact;
