import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { navigate } from 'gatsby';
import languages from '../data/languages';

const STORAGE_KEY = 'preferredLanguage';
const DEFAULT_LANG = 'en-US';
const LanguageContext = createContext();

const LanguageProvider = ({ children, locale = DEFAULT_LANG }) => {
  const [preferredLanguage, setPreferredLanguage] = useState(locale);

  // /* If mounting and we have not set local storage for language yet,
  //    set the preferred language, and navigate to correct url. */
  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     let lang = window.navigator.language

  //     if (!languages.find(l => l.locale === lang)) {
  //       lang = DEFAULT_LANG
  //     }

  //     const path = languages.find(l => l.locale === lang).path

  //     if (!localStorage.getItem(STORAGE_KEY)) {
  //       setPreferredLanguage(lang)
  //       navigate(`${path ? `/${path}/` : ""}${slug === "home" ? "" : slug}`)
  //     } else {
  //       setPreferredLanguage(localStorage.getItem(STORAGE_KEY))
  //     }
  //   }
  // }, [])

  // useEffect(() => {
  //   localStorage.setItem(STORAGE_KEY, preferredLanguage)
  // }, [preferredLanguage])

  // const value = useMemo(() => [preferredLanguage, setPreferredLanguage], [
  //   preferredLanguage,
  //   setPreferredLanguage,
  // ])

  return (
    <LanguageContext.Provider value={locale}>
      {children}
    </LanguageContext.Provider>
  );
};

const useLanguage = () => {
  return useContext(LanguageContext) ?? 'en-US';
};

export { LanguageProvider, useLanguage };
