import { useState, useEffect } from 'react';
export default function useWindowWidth() {
    const isSSR = typeof window === 'undefined';
    const [windowSize, setWindowSize] = useState(isSSR ? 1200 : window.innerWidth);
    function changeWindowSize() {
        setWindowSize(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', changeWindowSize);
        return () => {
            window.removeEventListener('resize', changeWindowSize);
        };
    }, []);
    return windowSize;
}
