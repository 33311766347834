import { ApolloClient, InMemoryCache } from "@apollo/client"
import fetch from "isomorphic-fetch"

const CONTENTFUL_GRAPHQL_URI =
  "https://graphql.contentful.com/content/v1/spaces"

export const client = new ApolloClient({
  uri: `${CONTENTFUL_GRAPHQL_URI}/${process.env.GATSBY_CONTENTFUL_SPACE_ID}?access_token=${process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN}`,
  fetch,
  cache: new InMemoryCache(),
})
