import React from 'react';
import { Box, Flex } from '../Rebass';
import Heading from '../Heading';
import Text from '../Text';
const Newsletter = ({ headline, subtitle, renderForm, headlineSx, sx, }) => (React.createElement(Box, { "data-testid": "newsletter", sx: sx },
    React.createElement(Flex, { flexDirection: ['column', 'row'], justifyContent: "space-between", mb: [4, 10] },
        React.createElement(Box, { flex: "1 0 50%" },
            React.createElement(Heading, { size: "h2", sx: { ...headlineSx } }, headline)),
        React.createElement(Box, { flex: "1 1 auto" },
            React.createElement(Text, { size: "body", color: "textDark" }, subtitle))),
    renderForm && renderForm()));
export default Newsletter;
