import { createElement } from 'react';
import GalleryTriptic from './GalleryTriptic';
import GalleryCarousel from './GalleryCarousel';
import GalleryBanner from './GalleryBanner';
import GalleryGrid from './GalleryGrid';
const galleryVariants = {
    triptic: GalleryTriptic,
    carousel: GalleryCarousel,
    banner: GalleryBanner,
    grid: GalleryGrid,
};
const Gallery = ({ variant, children, ...props }) => createElement(galleryVariants[variant], { ...props, variant }, children);
export default Gallery;
