// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-planyourtrip-js": () => import("./../../../src/pages/planyourtrip.js" /* webpackChunkName: "component---src-pages-planyourtrip-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/article-template.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-author-template-js": () => import("./../../../src/templates/author-template.js" /* webpackChunkName: "component---src-templates-author-template-js" */),
  "component---src-templates-career-template-js": () => import("./../../../src/templates/career-template.js" /* webpackChunkName: "component---src-templates-career-template-js" */),
  "component---src-templates-gallery-template-js": () => import("./../../../src/templates/gallery-template.js" /* webpackChunkName: "component---src-templates-gallery-template-js" */),
  "component---src-templates-lodge-activity-template-js": () => import("./../../../src/templates/lodge-activity-template.js" /* webpackChunkName: "component---src-templates-lodge-activity-template-js" */),
  "component---src-templates-lodge-information-template-js": () => import("./../../../src/templates/lodge-information-template.js" /* webpackChunkName: "component---src-templates-lodge-information-template-js" */),
  "component---src-templates-lodge-template-js": () => import("./../../../src/templates/lodge-template.js" /* webpackChunkName: "component---src-templates-lodge-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-project-template-js": () => import("./../../../src/templates/project-template.js" /* webpackChunkName: "component---src-templates-project-template-js" */),
  "component---src-templates-promotion-template-js": () => import("./../../../src/templates/promotion-template.js" /* webpackChunkName: "component---src-templates-promotion-template-js" */),
  "component---src-templates-video-template-js": () => import("./../../../src/templates/video-template.js" /* webpackChunkName: "component---src-templates-video-template-js" */)
}

