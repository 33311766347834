import React, { createContext, useState } from 'react';

const AvailabilityContext = createContext({
  showAvailability: false,
  setShowAvailability: () => {},
  showPlanYourTrip: true,
  setShowPlanYourTrip: () => {},
});

export const AvailabilityProvider = ({ children }) => {
  const [showAvailability, setShowAvailability] = useState(false);
  const [showPlanYourTrip, setShowPlanYourTrip] = useState(true);

  return (
    <AvailabilityContext.Provider
      value={{
        showAvailability,
        setShowAvailability,
        showPlanYourTrip,
        setShowPlanYourTrip,
      }}
    >
      {children}
    </AvailabilityContext.Provider>
  );
};

export default AvailabilityContext;
