import React from 'react';
import get from 'lodash/get';
import Modal from 'react-modal';
import ModalRoutingContext from './src/context/ModalRoutingContext';

const withoutPrefix = (path) => {
  const prefix =
    typeof __BASE_PATH__ !== `undefined` ? __BASE_PATH__ : __PATH_PREFIX__;

  return path.slice(prefix ? prefix.length : 0);
};

const ComponentRenderer = (props) => {
  const pathname = React.useRef(null);
  const modalPage = React.useRef(null);
  const originPage = React.useRef(null);

  const contentRef = React.useRef();

  const { location, navigate, pageResources, modalProps } = props;

  React.useEffect(() => {
    if (props.location.pathname !== pathname.current) {
      pathname.current = props.location.pathname;
      if (get(props.location, 'state.isModal', false)) {
        modalPage.current = props;
      } else {
        originPage.current = props;
      }
    }
  }, [props]);

  React.useLayoutEffect(() => {
    if (
      location.pathname !== pathname.current &&
      get(location, 'state.isModal') &&
      contentRef.current
    ) {
      // modal remains open after path change, so make sure it's scrolled to the top
      contentRef.current.scrollTop = 0;
    }
  });
  const isModal = !!originPage.current && get(location, 'state.isModal', false);
  const pageElement = isModal
    ? React.createElement(originPage.current.pageResources.component, {
        ...originPage.current,
        key: originPage.current.pageResources.page.path,
      })
    : React.createElement(pageResources.component, {
        ...props,
        key: pageResources.page.path,
      });

  let modalElement = null;

  if (isModal) {
    modalElement = React.createElement(pageResources.component, {
      ...props,
      key: pageResources.page.path,
    });
  } else if (modalPage.current) {
    modalElement = React.createElement(
      get(modalPage.current, 'pageResources.component'),
      {
        ...modalPage.current,
        key: get(modalPage.current, 'pageResources.page.path'),
      },
    );
  }
  const prevModalUrl = get(location, 'state.prevModalUrl', null);
  return (
    <>
      {pageElement}
      <Modal
        onRequestClose={() => {
          navigate(
            withoutPrefix(prevModalUrl || originPage.current.location.pathname),
            {
              state: { noScroll: true },
            },
          );
        }}
        contentRef={(node) => (contentRef.current = node)}
        {...modalProps}
        isOpen={!!isModal}
      >
        {modalElement ? (
          <React.Fragment key={props.location.key}>
            <ModalRoutingContext.Provider
              value={{
                prevModalUrl,
                isModal: true,
                closeTo: originPage.current
                  ? withoutPrefix(
                      prevModalUrl || originPage.current.location.pathname,
                    )
                  : '/',
              }}
            >
              {modalElement}
            </ModalRoutingContext.Provider>
          </React.Fragment>
        ) : null}
      </Modal>
    </>
  );
};

const replaceComponentRenderer = ({ props }, opts) => {
  const { modalProps, pageAnimation } = opts;
  return React.createElement(ComponentRenderer, {
    ...props,
    modalProps,
    pageAnimation,
  });
};

export default replaceComponentRenderer;
