import React, { createContext, useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export const ResourceSetContext = createContext();

const GLOBAL_RESOURCES_QUERY = graphql`
  query getAllContentfulResourceSets {
    allContentfulResourceSet {
      nodes {
        node_locale
        name
        key
        value {
          internal {
            content
          }
        }
      }
    }
  }
`;

const parseNode = (node) => {
  return {
    component: node.key,
    resources: JSON.parse(node.value.internal.content),
  };
};

/** Output structure:
 *    {
 *      en: [{ component: "", resources: {} }],
 *      de: [{ component: "", resources: {} }]
 *    }
 */
const getStructuredResourceSets = (resourceSets) => {
  return resourceSets.reduce((acc, value) => {
    if (!acc[value.node_locale]) {
      acc[value.node_locale] = [parseNode(value)];
    } else {
      acc[value.node_locale].push(parseNode(value));
    }
    return acc;
  }, {});
};

export const getResourcesForComponent = (
  resourceSets,
  componentName,
  lang = 'en-US',
) => {
  return (
    getStructuredResourceSets(resourceSets)[lang ?? 'en-US'].find(
      (obj) => obj.component === componentName,
    )?.resources ?? {}
  );
};

export const ResourceSetProvider = ({ children }) => {
  const {
    allContentfulResourceSet: { nodes },
  } = useStaticQuery(GLOBAL_RESOURCES_QUERY);

  if (!nodes) {
    console.log('No Resource Sets found');
    return children;
  }

  return (
    <ResourceSetContext.Provider
      value={{
        resourceSets: nodes,
        getResourcesForComponent: (key, lang = 'en-US') =>
          getResourcesForComponent(nodes, key, lang),
      }}
    >
      {children}
    </ResourceSetContext.Provider>
  );
};

export function useResourceSets(key, language) {
  const context = useContext(ResourceSetContext);
  if (!context)
    throw new Error(
      'useResourceSets must be used within a ResourceSetProvider',
    );
  return getResourcesForComponent(context.resourceSets, key, language);
}
